import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
  <div class="wdth-dv">
  <h4 class="created-by RA-13-cpy-rght-txt">Copyright reseverd by SaaSbyt ♥ </h4>
  </div>
  `,
})
export class FooterComponent {
}
