import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment'
import { map } from 'rxjs/operators';
// import axios from 'axios';
// import Compressor from 'compressorjs';
// const attach="Bearer ";
// const attach1= JSON.parse(localStorage.getItem('auth_token'))
// const attach2= attach+attach1

// const httpOptions1 = {
//   headers: new HttpHeaders({
//       // 'Content-Type': undefined,
//       'Authorization': attach2
//   })
// }

// const httpOptions = {
//   headers: new HttpHeaders({
//       'Content-Type': 'application/json',
//       'Authorization': attach2
//   })
// }


@Injectable({
  providedIn: 'root'
})
export class ApiserviceService {


  public baseUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }


  //auth apis
  login(body) {
    return this.http.post(this.baseUrl + '/api/admin-portal/login/', body)
  }
  loginuser(body) {
    return this.http.post(this.baseUrl + '/api/user/login/', body)
  }
  signupuser(body) {
    return this.http.post(this.baseUrl + '/api/user/signup/', body)

  }
  logincompany(body) {
    return this.http.post(this.baseUrl + '/api/company/login/', body)
  }
  signupcompany(body) {
    return this.http.post(this.baseUrl + '/api/company/signup/', body)

  }
  //auth api end


  //location api

  addlocation(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + '/api/company/service-location/', body, httpOptions)

  }
  //location api end


  //service api start

  addservice(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + '/api/company/service/', body, httpOptions)

  }
  getservice() {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/service/', httpOptions)
  }
  getservicebyid(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/service/' + id + '/', httpOptions)
  }

  putservice(id, body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.put(this.baseUrl + '/api/company/service/' + id + '/', body, httpOptions)
  }
  //end of service api

    //fliter api

  //get category api

  getcategory() {
    return this.http.get(this.baseUrl + '/api/company/prod-category/')
  }

  getCategoryById() {
    return this.http.get(this.baseUrl + '/api/company/prod-category/' )
  }

  // Without Token
  getFilteredCategory(body) {
    return this.http.post(this.baseUrl + "/api/company/filter-subcategory-without-token/", body)
  }

  getsubcategory(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + "/api/company/filter-subcategory/", body, httpOptions)
  }
  // category api end


  // Country apis start
  postsate(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + '/api/company/state/', body, httpOptions)
  }
  getstate() {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/state/', httpOptions)
  }
  postcountry(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + '/api/company/service-location/', body, httpOptions)
  }

  //pricing model api
  postmodel(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + "/api/company/pricing/", body, httpOptions)
  }
  getmodels(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + "/api/company/pricing/" + id + "/", httpOptions)
  }

  //Assignpayment Plan Features

  postpaymentplan(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + '/api/company/assign-payment/', body, httpOptions)
  }
  postpaymentplanproduct(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + '/api/company/assign-product-payment/', body, httpOptions)
  }
  getpaymentplansofproduct(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/assign-product-payment/' + id + '/', httpOptions)
  }

  delmodels(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.delete(this.baseUrl + "/api/company/pricing/" + id + "/", httpOptions)
  }


  //---------------------------Product Section----------------------//
  delproduct(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.delete(this.baseUrl + '/api/company/product/' + id + '/', httpOptions)
  }


  addProductFeature(id, body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2,

      })
    }
    return this.http.put(this.baseUrl + '/api/company/product-feature/' + id + '/', body, httpOptions)
  }
  getFeatures(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }

    return this.http.get(this.baseUrl + '/api/company/product-feature/' + id + '/', httpOptions)
  }
  addProductModuleFeature(id, body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2,

      })
    }
    return this.http.put(this.baseUrl + '/api/company/product-modulefeature/' + id + '/', body, httpOptions)
  }
  getModuleFeature(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }

    return this.http.get(this.baseUrl + '/api/company/product-modulefeature/' + id + '/', httpOptions)
  }
  getproductlinksbyid(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/product-links/' + id + '/', httpOptions);

  }
  getproductdetailbyid(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/product-detail/' + id + '/', httpOptions);

  }
  postproduct(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + '/api/company/product/', body, httpOptions)
  }
  getproducts(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }

    return this.http.get(this.baseUrl + '/api/company/product/' + id + '/', httpOptions)
  }
  updateproduct(id, body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.put(this.baseUrl + '/api/company/product/' + id + '/', body, httpOptions)
  }
  putreviewproduct(id, body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.put(this.baseUrl + '/api/company/product/' + id + '/', body, httpOptions)
  }
  getproductbyid(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/product/' + id + '/', httpOptions)
  }

  //admin side get all user and vendor
  getcompanylist() {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    console.log("auth token", httpOptions)
    return this.http.get(this.baseUrl + '/api/company/company-list/', httpOptions)
  }
  getcompanydetailbycid(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/company-detail/' + id + '/', httpOptions)
  }

  updatecompanydetailbycid(id, body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.put(this.baseUrl + '/api/company/company-detail/' + id + '/', body, httpOptions)
  }

  requestmoreproducts(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + '/api/admin-portal/request/', body, httpOptions)
  }
  //public apis

  getallps() {

    return this.http.get(this.baseUrl + '/api/company/public-products/')
  }

  getspecificsdetail(id) {
    return this.http.get(this.baseUrl + '/api/company/public-services/' + id + '/')
  }
  getspecificpdetail(id) {
    return this.http.get(this.baseUrl + '/api/company/public-products/' + id + '/')
  }

  getProductReviewsApi(id: number) {
    return this.http.get(this.baseUrl + '/api/company/product-reviews/' + id + '/');
  }



  //image uplaod api
  uploadCategoryImage(productId, file) {
    //   new Compressor(file, {
    //     quality: 0.6,
    //     success(result) {
    //       const formData = new FormData();

    //       // The third parameter is required for server
    //       formData.append('image', result);
    //    const attach="Bearer ";
    // const attach1= JSON.parse(localStorage.getItem('auth_token'))
    // const attach2= attach+attach1

    // const httpOptions1 = {
    //   headers: new HttpHeaders({
    //       // 'Content-Type': undefined,
    //       'Authorization': attach2
    //   })
    // }
    //       // Send the compressed image file to server with XMLHttpRequest.
    //       axios.put(this.baseUrl+'/api/company/upload/'+productId+'/', formData,httpOptions1).then(() => {
    //         console.log('Upload success');
    //       });
    //     },
    //     error(err) {
    //       console.log(err.message);
    //     },
    //   });
    const formDataHere = new FormData();
    formDataHere.append('image', file);
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1

    const httpOptions1 = {
      headers: new HttpHeaders({
        // 'Content-Type': undefined,
        'Authorization': attach2
      })
    }
    return this.http.put(this.baseUrl + '/api/company/upload/' + productId + '/', formDataHere, httpOptions1);
  }
  uploadmultiImage(productId, file) {


    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1

    const httpOptions1 = {
      headers: new HttpHeaders({
        // 'Content-Type': 'multipart/form-data',
        'Authorization': attach2
      })
    }
    const formDataHere = new FormData();
    for (let i = 0; i < file.length; i++) {
      formDataHere.append('images', file[i]);
    }
    return this.http.post(this.baseUrl + '/api/company/media-files/' + productId + '/', formDataHere, httpOptions1);
  }





  //revire of product
  reviewonproduct(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + "/api/user/product-review/", body, httpOptions)
  }

  //reviews on company
  reviewoncompany(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/user/get-company-reviews/' + id + '/', httpOptions)
  }
  userreviews(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/user/get-user-reviews/' + id + '/', httpOptions)
  }


  //get all review against a product
  reviewagainstproduct(id) {

    return this.http.get(this.baseUrl + '/api/user/get-product-reviews/' + id + '/')
  }

  //save fav product user
  saveproduct(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + '/api/user/saved-products/', body, httpOptions)
  }
  getsavedproducts(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/user/saved-products/' + id + '/', httpOptions)
  }
  updatedsavedlist(id, body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.put(this.baseUrl + '/api/user/saved-products/' + id + '/', body, httpOptions)
  }


  //fliter api
  flitercat(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/filter-products/' + id + '/')
  }

  delproductpayment(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.delete(this.baseUrl + '/api/company/assign-product-payment/' + id + '/', httpOptions)
  }
  getalluserandvendor() {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/get-all/')
  }


  getallusers() {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/user-list/', httpOptions)
  }



  //roles apis
  postrole(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + '/api/admin-portal/roles/', body, httpOptions)
  }
  postpermission1(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + '/api/admin-portal/permissions/', body, httpOptions)
  }

  getroles() {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/admin-portal/roles/', httpOptions)
  }

  postsubadmin(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + '/api/admin-portal/sub-admin-signup/', body, httpOptions)
  }
  getadmins() {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/admin-portal/sub-admin-signup/', httpOptions)
  }

  getpermissions() {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/admin-portal/permissions/', httpOptions)
  }




  //compan profile
  getcompanyprofile(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/update-company-profile/' + id + '/', httpOptions)
  }

  updatecompanyprofile(id, body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions12 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    console.log("attach 2", attach2)
    return this.http.put(this.baseUrl + '/api/company/update-company-profile/' + id + '/', body, httpOptions12)

  }
  //user profile
  getuserrofile(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/user/update-user-profile/' + id + '/', httpOptions)
  }


  updateuserprofile(id, body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions12 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    console.log("attach 2", attach2)
    return this.http.put(this.baseUrl + '/api/user/update-user-profile/' + id + '/', body, httpOptions12)

  }
  changepassword(id, body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions12 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    console.log("attach 2", attach2)
    return this.http.put(this.baseUrl + '/api/company/change-password/' + id + '/', body, httpOptions12)

  }

  setpermissions(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions12 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    console.log("attach 2", attach2)
    return this.http.post(this.baseUrl + '/api/admin-portal/admin-roles-permission/', body, httpOptions12)

  }



  //upload company profile image
  uploadcompanyImage(productId, file: File) {
    const formDataHere = new FormData();
    formDataHere.append('image', file);
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1

    const httpOptions1 = {
      headers: new HttpHeaders({
        // 'Content-Type': undefined,
        'Authorization': attach2
      })
    }

    return this.http.put(this.baseUrl + '/api/company/update-company-profile/' + productId + '/', formDataHere, httpOptions1);
  }
  //user profile updta
  uploaduserImage(productId, file: File) {
    const formDataHere = new FormData();
    formDataHere.append('image', file);
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1

    const httpOptions1 = {
      headers: new HttpHeaders({
        // 'Content-Type': undefined,
        'Authorization': attach2
      })
    }

    return this.http.put(this.baseUrl + '/api/user/user-profile-image/' + productId + '/', formDataHere, httpOptions1);
  }


  getproductsbysubcat(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions12 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    console.log("attach 2", attach2)
    return this.http.post(this.baseUrl + '/api/company/product-by-subcategory/', body, httpOptions12)

  }

  requestsubcat(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions12 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    console.log("attach 2", attach2)
    return this.http.post(this.baseUrl + '/api/company/request-subcategory/', body, httpOptions12)

  }

  requestCategoryApi(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1

    const httpOptions12 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    console.log("attach 2", attach2)
    return this.http.post(this.baseUrl + '/api/company/prod-category/', body, httpOptions12)
  }

  getSubCategories(id) {

    return this.http.get(this.baseUrl + '/api/company/prod-subcategory/'+ id + '/')
  }
  requestSubCategoryApi(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1

    const httpOptions12 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    console.log("attach 2", attach2)
    return this.http.post(this.baseUrl + '/api/company/prod-subcategory/', body, httpOptions12)
  }

  requestcat(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions12 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    console.log("attach 2", attach2)
    return this.http.post(this.baseUrl + '/api/company/request-category/', body, httpOptions12)

  }

  gettoken(id) {
    console.log(id);
    //   const httpOptions12 = {
    //   headers: new HttpHeaders({
    //     'statusCode': '200',
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //     'Access-Control-Allow-Credentials': 'true',
    //        'Access-Control-Allow-Origin': '*',
    //        "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
    //        "Access-Control-Allow-Headers": "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With"
    //   })
    // }

    const header = {
      headers: new HttpHeaders({
        "Content-Type": "application/x-www-form-urlencoded",
        // "Access-Control-Allow-Origin": "http://localhost:4200/"
      }),

    };
    // return this.http.get('https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&client_id=86qlz2uo91vift&client_secret=3YNgukdo6ZutU9Xk&code='+id+'&redirect_uri=http://localhost:4200/',header)   
    // return this.http.get('https://api.allorigins.win/get?url=' + encodeURIComponent('https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&client_id=86qlz2uo91vift&client_secret=3YNgukdo6ZutU9Xk&code=' + id + '&redirect_uri=https://saasbyt.com/'))
    return this.http.get('https://api.allorigins.win/get?url=' + encodeURIComponent('https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&client_id=771j77jjioln39&client_secret=lYeIdTX5vG0g7y5S&code=' + id + '&redirect_uri=https://saasbyt.com'))
    //  const proxyUrl = "https://cors-anywhere.herokuapp.com/";
    //  const header = {
    //           headers: new HttpHeaders({
    //             "Content-Type": "application/x-www-form-urlencoded",
    //           }),

    //   return this.http.get("https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&client_id=86qlz2uo91vift&client_secret=3YNgukdo6ZutU9Xk&code="+id+"&redirect_uri=http://localhost:4300/")
    // }
  }
  getmyprofile(token) {

    return this.http.get('https://api.allorigins.win/get?url=' + encodeURIComponent('https://api.linkedin.com/v2/emailAddress?q=members&projection=(elements*(handle~))&oauth2_access_token=' + token.access_token))
  }
  getprofiel(token) {
    return this.http.get('https://api.allorigins.win/get?url=' + encodeURIComponent('https://api.linkedin.com/v2/me?oauth2_access_token=' + token.access_token))
  }
  getProfileImage(token) {
    return this.http.get('https://api.allorigins.win/get?url=' + encodeURIComponent('https://api.linkedin.com/v2/me?projection=(profilePicture(displayImage~:playableStreams))&oauth2_access_token=' + token.access_token))
  }
  activeaccount(id, body) {
    return this.http.put('https://saasbyte.edgecare.io/api/company/verify-email/' + id + '/', body)
  }
  requestmorecategory(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions12 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post('https://saasbyte.edgecare.io/api/company/request-multicategory/', body, httpOptions12)
  }


  getEstablishedWall() {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions12 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get('https://saasbyte.edgecare.io/api/admin-portal/established-wall' + '/', httpOptions12);
  }


  updateEstablishedSpot(id, body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions12 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.put('https://saasbyte.edgecare.io/api/admin-portal/established-spot/' + id + '/', body, httpOptions12);
  }

  uploadSpotLogo(spotId, file: File) {
    const formDataHere = new FormData();
    formDataHere.append('default_logo', file);
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1

    const httpOptions1 = {
      headers: new HttpHeaders({
        // 'Content-Type': undefined,
        'Authorization': attach2
      })
    }
    return this.http.put('https://saasbyte.edgecare.io/api/admin-portal/established-spot-image/' + spotId + '/', formDataHere, httpOptions1);
  }

  getStartupsProducts(id) {
    return this.http.get('https://saasbyte.edgecare.io/api/company/filter-categoryproducts/' + id + '/');
  }

  getStartupsWall() {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions12 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get('https://saasbyte.edgecare.io/api/admin-portal/startup-wall/', httpOptions12);
  }

  addFounder(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + '/api/company/founder/', body, httpOptions)
  }
  getFounder() {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/founder/', httpOptions)
  }

  getFounderById(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/founder/' + id + "/", httpOptions)
  }

  updateFounderById(id, body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.put(this.baseUrl + '/api/company/founder/' + id + "/", body, httpOptions)
  }
  deleteFounder(id) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.delete(this.baseUrl + '/api/company/founder/' + id + "/", httpOptions)
  }

  //upload company profile image
  uploadFounderImage(founderId, file: File) {
    const formDataHere = new FormData();
    formDataHere.append('founder_image', file);
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1

    const httpOptions1 = {
      headers: new HttpHeaders({
        // 'Content-Type': undefined,
        'Authorization': attach2
      })
    }

    return this.http.put(this.baseUrl + '/api/company/founder-image/' + founderId + '/', formDataHere, httpOptions1);
  }

  getSpotsQueue() {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/queue-management/', httpOptions)
  }

  // Company Reset Password Process
  confirmationEmail(body) {
    return this.http.post(this.baseUrl + '/api/company/forgot-password/', body)
  }

  confirmationCode(id, body) {
    return this.http.put(this.baseUrl + '/api/company/password-verify-email/' + id + "/", body)
  }

  changePassword(id, body) {
    return this.http.put(this.baseUrl + '/api/company/forgot-password/' + id + "/", body)
  }

  getNotificationsApi() {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/prod-category/', httpOptions);
  }

  getNotificationsByIdApi(id: String) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/notifications/' + id + "/", httpOptions);
  }

  createNotificationApi(body) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1
    console.log('notification body====>', body);

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + '/api/company/notifications/', body, httpOptions);
  }

  updateNotificationApi(id: String, name: String, description: String, type: String, status: String) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1;
    const body = {
      name: name || "---",
      description: description || "---",
      type: type || "---",
      status: status || "---"
    }


    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': attach2
      })
    }
    return this.http.put(this.baseUrl + '/api/company/notifications/' + id + "/", body, httpOptions);
  }

  productFiltersApi(body: any) {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': attach2
      })
    }
    return this.http.post(this.baseUrl + '/api/company/search-filters/', body, httpOptions);
  }

  fetchFilterCategoriesApi() {
    const attach = "Bearer ";
    const attach1 = JSON.parse(localStorage.getItem('auth_token'))
    const attach2 = attach + attach1

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': attach2
      })
    }
    return this.http.get(this.baseUrl + '/api/company/category-list/', httpOptions);
  }


}